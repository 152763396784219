
:root {
  --text-color: #000000;
  --hr-color: #26292a;
  --transition-duration: 0.4s;
  --transition-delay: 0.3s;
  --red: #e74c3c;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
  
}
body {
  font: 22px sans-serif;
  height: 100vh;
  background-color: #EEEBDD;
}

a,p,div,h1,h2,h3,h4,h5,span, ul,li {
  font-family: 'Lato', sans-serif;
}
/*Header*/

.header { 
  padding: 0.1rem 0 3rem 0;
  background-position: center center;
}

@media (max-width: 768px) {
  .header {
    min-height: 15rem;
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.logo-desktop a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo-desktop img:nth-child(1){
  max-width: 70px;
}
.logo-desktop img:nth-child(2){
  max-width: 100px;
  margin-top: -5px;
}
.logo img {
  width: 6rem;
  height: 6rem;
  padding-right: .5rem;
  margin-right: .5rem;
  cursor: pointer;
}
.logo img:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

div.container-imagen {
  width: 100%;
}

div.container {
  width: 100%;
  margin-top: 20px;
}
div.container h3 {
  height: 80px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
}

/* seccion para los links del navbar */

ul {
  list-style: none;
}

li a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-weight: 600;
  font-size: 1.2rem;
}

li a:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  transition: 0.5s;
}
.menu {
  display: flex;
  justify-content: center;
}

.menu li {
  margin-right: 2rem;
}

.menu a {
  position: relative;
  display: block;
  padding: 0.5rem 2rem;
}

.menu .border {
  position: absolute;
  left: 0;
  background: currentColor;
  transition: transform var(--transition-duration) ease-in-out;
}

.menu .border-bottom {
  width: 100%;
  height: 1px;
  transform: scaleX(0);
}

.menu .border-bottom {
  bottom: 0;
  transform-origin: bottom right;
}

.menu a:hover .border-bottom {
  transform: scaleX(1);
}

.menu[data-animation="diagonal"] a:hover [class^="border"] {
  transition-delay: 0s;
}

/* section de main */
.columnas-banner{
  width: 100%;
  max-width: 2440px;
  top: 0;
  position: absolute;
  z-index: 200;
  display: flex;
  justify-content: space-between;
}
.image-banner1,
.image-banner2,
.image-banner3,
.image-banner4{
  max-width: 25%;
  object-fit: cover;
  height:80vh;
  margin-top: 113px;
}
.image-banner2{
  object-position: left;
}
.image-banner3{
  object-position: top;
}
.content-inicio {
  width: 100%;
  margin-top: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-inicio p{
  width: 80%;
  text-align: justify;
  margin-top: 50px;
}
.container-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45vh;
}
.titulo-mobile{
  display: none;
 
}
@media screen and (max-width:768px){
  .titulo-mobile{
    display: block;
    z-index: 300;
    color: #fff;
    margin-top: -70%;
  }
  .titulo-mobile:hover{
    color: #000;
  }
}
#nosotros,
#servicios,
#noticias {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 30vh;
}
.banner-nosotros{
  width: 100%;
  max-width: 2440px;
  top: 0;
  position: absolute;
  z-index: 200;
}
.img-nosotros,
.img-servicios {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  object-position: bottom;
  margin-top: 113px;
}

.nosotros-content,
.servicios-content,
.noticias-content {
  width: 100%;
  margin-top: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nosotros-vm {
  width: 80%;
}
.nosotros-vm h2 {
  margin-bottom: 40px;
}
.nosotros-vm p {
  text-align: justify;
}
.dos-columnas {
  width: 80%;
  height: 800px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  margin-bottom: 100px;
}
.columna1,
.columna2 {
  width: 50%;
}
.columna1{
  margin-right: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.columna1 p,
.content-somos p {
  text-align: justify;
}
.columna2 {
  padding-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.columna2 img {
  width: 100%;
  max-width: 680px;
}
.quienes-somos {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quienes-somos h3 {
  margin: 30px 0;
}
.card-somos {
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items:center;
  margin: 30px 0;
}

.content-somos {
  width: 65%;
}
h2{
  width: 100%;
  text-align: center;
  padding-top: 8rem;
}
#nosotro h2{
  padding-top: 2rem;
}
.imgnos{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.imgnos img{
  width: 260px;
  height: 250px;
  margin-left: 4rem;
  border-radius: 50%;
  object-fit: cover;
}
.texto-nosotros{
  width: 60%;
  text-align: center;
}
.servicios-content h2 {
  margin-bottom: 30px;
}
.card-servicio {
  width: 80%;
  margin: 20px 0;
}
.list-servicios {
  max-width: 50%;
  list-style-type: circle;
  text-align:justify;
}
.card-noticias img {
  width:100%; 
  height: 400px;
  object-fit: cover; 
  border-radius: 20px;
}
@media (max-width:768px){
  .image-banner1,
  .image-banner2,
  .image-banner4{
    display:none;
  }
  .image-banner3{
    max-width: 100%;
  }
  .texto-nosotros{
    width: 90%;
  }
  .logo a {
    display: flex;
    justify-content: center;
  }
  .imgnos {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .imgnos img{
    margin-bottom: 2rem;
    margin-left: 0;
  }
  .dos-columnas {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .columna1,
  .columna2 {
    width: 90%;
    padding: 0;
  }
  .card-somos{
    display: flex;
    flex-direction: column !important;
  }
  .content-somos {
    width: 90%;
  }
  .content-somos a{
    font-size: 16px;
  }
  .card-servicio {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .card-servicio h3{
    margin-bottom: 15px;
  }
  .body-servicios {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .body-servicios img {
    margin: 15px 0 25px 0;
  }
  .list-servicios {
    max-width: 80% !important;
  }
  .noticias-content .cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card-noticias {
    width: 95% !important;
  }
  .card-noticias .container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card-noticias h3 {
    margin: 20px 0;
  }
  .card-noticias p {
    width: 90%;
    text-align: justify;
    margin-bottom: 40px;
  }
  .correo-wild {
    font-size: 17px !important;
  }

  .hashtag-wild img {
    max-width: 350px !important;
  }
}

#noticias{
  width: 100%;
  
}
.cards{
  width: 100%;
  color: #053354;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.card-noticias{
  height: auto;
  max-width: 400px;
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
}
.card-noticias p {
  text-align: justify;
}
.correo-wild {
  font-size: 21px;
}
.card img{
  width: 100%;
  height: auto; 
}
.card .container{
  width: 95%;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}
.card .container h3{
  margin-bottom: 40px;
  text-align: center !important;
}
@media (max-width:768px){
  .cards{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .card{
    width: 100%;
    height: auto;
    display: flex;
    margin-left: 0;
    flex-direction: column;
    justify-content: space-around;
  }
  .card .container{
    width: 100%;
    padding: 2rem ;
  }
  .card:nth-child(2){
    margin-left: 0;
  }
  .card-noticias img{
    width: 100%;
    height: auto;
  }
  /* .card-noticias h3 {
    width: 400px;
  } */
  /* .card-noticias img {
    max-width: 400px;
  } */
}
#contacto{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
#contacto h2 {
  padding-top: 3rem !important;
}
.info{
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  line-height: 3;
  margin-bottom: 10px;;
}

.info p{
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: bold;
}
.info p:last-of-type,
.info p:nth-child(2){
  font-size: 1rem;
  color: #D49D42;
}
.info-redes{
  width: 40%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.info-redes a{
  display: flex;
  justify-content: center;
  text-decoration: none;
}
.info-redes a i{
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #4e3620;
}
@media screen and (min-width:768px){
  .info p:last-of-type,
  .info p:nth-child(2){
  
  white-space: nowrap;
  
}
}
@media (max-width: 768px) {
  .info-redes{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .info-redes a{
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
  .info-redes a i:last-child{
    font-size: 2rem;
    margin-bottom: 1.5rem;
    margin-right: 0;
  } 
  .info a {
    font-size: 20px;
  }
}

/* final section de main */


/*descripcion de Footer P*/
.hashtag-wild img{
  max-width: 450px;
}
.jumbotron {
  padding: 3rem;
}

#footerEdit {
  font-family: verdana, Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

#footterCopyright {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

/****************************************************
finaliza CSS de la configuracon de "Footer"
****************************************************/


/****************************************************
inicia CSS de la configuracon de "Galery"
****************************************************/


.DivGalleryMain{
  background-color: #464F41;
  width: 100%; 
  margin-top: 61px;
  height: 100%;
}

h1.TitleGallery{
  text-align:center;
  color: #FFFFFF;
  padding-top: 30px;
}

.galeria-texto {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-gallery{
  width: 90%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 250px;
  grid-gap: .8rem;
  padding: 1.5rem;
}
.cont-img {
  position: inherit;
}
.cont-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
.cont-img img:hover {
  width: 100%;
  height: 100%;
}
.cont-img h3{
  position: absolute;
  bottom: 0;
  color: #fff;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1));
  width: 100%;
  margin: 0;
  padding: 5px;
}
.cont-img:nth-child(1) {
  grid-column: 1 / 4;
  grid-row-start: span 2;
}
.cont-img:nth-child(2) {
  grid-column: 4 / 6;
}
.cont-img:nth-child(3) {
  grid-row-start: span 2;
  grid-column-start: span 2;
}
.cont-img:nth-child(4) {
  grid-column-start: span 2;
}
.cont-img:nth-child(5) {
  grid-column: 1 / 3;
}
.cont-img:nth-child(6) {
  grid-column: 3 / 5;
  grid-row-start: span 2;
}
.cont-img:nth-child(7) {
  grid-column: 5 / span 3;
  grid-row-start: span 2;
}
.cont-img:nth-child(8) {
  grid-column-start: span 2;
}
.cont-img:nth-child(9) {
  grid-column-start: span 2;
  grid-row-start: span 2;
}
.cont-img:nth-child(10) {
  grid-column-start: span 2;
}
.cont-img:nth-child(11) {
  grid-column: span 1;
}
.cont-img:nth-child(12) {
  grid-column: span 2;
  grid-row-start: span 2;
}
.cont-img:nth-child(13) {
  grid-column-start: span 2;
}
.parrafo {
  width: 35%;
  color: #dddddd;
}

@media(max-width:768px){
  
  .galeria-texto {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .container-gallery{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .cont-img {
    position: inherit;
  }
  .cont-img img {
    width: 100%;
    height: auto;
    
  }
  .cont-img h3{
    margin: 0;
    padding: 0;
    font-size: 0;
  }
  .parrafo {
    width: 90%;
    margin-bottom: 2rem;
  }
}

/****************************************************
finaliza CSS de la configuracon de "Galery"
****************************************************/

