/* stylelint-disable docusaurus/copyright-header */
/**
 * Any CSS included here will be global. The classic template
 * bundles Infima by default. Infima is a CSS framework designed to
 * work well for content-centric websites.
 */

/* You can override the default Infima variables here. */
:root {
	--ifm-color-primary: #25c2a0;
	--ifm-color-primary-dark: rgb(33, 175, 144);
	--ifm-color-primary-darker: rgb(31, 165, 136);
	--ifm-color-primary-darkest: rgb(26, 136, 112);
	--ifm-color-primary-light: rgb(70, 203, 174);
	--ifm-color-primary-lighter: rgb(102, 212, 189);
	--ifm-color-primary-lightest: rgb(146, 224, 208);
	--ifm-code-font-size: 95%;
}

.docusaurus-highlight-code-line {
	background-color: rgb(72, 77, 91);
	display: block;
	margin: 0 calc(-1 * var(--ifm-pre-padding));
	padding: 0 var(--ifm-pre-padding);
}

.exampleCarousel1 div > button[data-direction='left'] {
    width: 50px;
	background: url('https://cdn.dsmcdn.com/web/production/slick-arrow.svg') no-repeat
		center;
	transform: rotateZ(180deg);
	outline: none;
	border: none;
	cursor: pointer;
	position: relative;
	left: -16px;
	height: 80px;
	top: 80px;
    margin-left: 50px;
}

.exampleCarousel1 div > button[data-direction='right'] {
    width: 50px;
	background: url('https://cdn.dsmcdn.com/web/production/slick-arrow.svg') no-repeat
		center;
	outline: none;
	border: none;
	cursor: pointer;
	position: relative;
	right: -16px;
	height: 80px;
	top: 80px;
    margin-right: 50px;
}

.exampleCarousel1 div:first-child {
	text-align: center;
}

.scrolling-carousel-example1-container {
	width: 660px;
}

.scrolling-carousel-example1 > [data-arrow='left'] {
	background: linear-gradient(90deg, #fff 10%, rgba(255, 255, 255, 0));
	top: 0px;
	left: 0;
	opacity: 1;
	height: 42px;
	width: 88px;
	position: absolute;
	cursor: pointer;
	bottom: 0;
	margin-bottom: auto;
	margin-top: auto;
}
.scrolling-carousel-example1 > [data-arrow='left'] > button {
	background: url('https://cdn.dsmcdn.com/web/production/slick-arrow.svg') no-repeat
		center;
	transform: rotateZ(180deg) scale(0.5);
	height: 40px;
	left: -13px;
	border: none;
	outline: none;
	cursor: pointer;
}

.scrolling-carousel-example1 > [data-arrow='right'] {
	background: linear-gradient(270deg, #fff 10%, rgba(255, 255, 255, 0));
	right: 0;
	opacity: 1;
	height: 42px;
	width: 88px;
	position: absolute;
	cursor: pointer;
	top: 0;
	bottom: 0;
	margin-bottom: auto;
	margin-top: auto;
}
.scrolling-carousel-example1 > [data-arrow='right'] > button {
	background: url('https://cdn.dsmcdn.com/web/production/slick-arrow.svg') no-repeat;
	height: 40px;
	transform: scale(0.5);
	border: none;
	outline: none;
	position: absolute;
	right: -16px;
	cursor: pointer;
}

[data-theme='dark'] .scrolling-carousel-example1 > [data-arrow='right'] {
	background: linear-gradient(270deg, #121212 10%, rgba(255, 255, 255, 0));
}

[data-theme='dark'] .scrolling-carousel-example1 > [data-arrow='left'] {
	background: linear-gradient(90deg, #121212 10%, rgba(255, 255, 255, 0));
	top: 0px;
}

.sliderBase {
	width: 100%;
	position: relative;
}

.slider {
	display: flex;
	overflow-x: auto;
	scrollbar-width: none; /* Firefox 64 */
	-ms-overflow-style: none; /* Internet Explorer 11 */
}

.slider::-webkit-scrollbar {
	/** WebKit */
	display: none;
}

.slider > * {
	flex: 0 0 auto;
}

.sliding > * {
	pointer-events: none;
}

.carousel-base {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	outline: none;
}

.item-provider {
	overflow: hidden;
	width: 100%;
	cursor: pointer;
}

.item-container img {
	user-select: none;
	-webkit-user-drag: none;
}

.item-tracker {
	height: 100%;
	display: flex;
}

.carousel-arrow {
	z-index: 1;
}